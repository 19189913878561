export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-account-network', key: 'menu.blank', text: 'Coordinators', link: '/stateCoor' },
    { icon: 'mdi-badge-account', key: 'menu.blank', text: 'Data Clerk Status', link: '/dcStatus' },
    //{ icon: 'mdi-account-edit', key: 'menu.blank', text: 'Data Clerk', link: '/dataClerk' },
    { icon: 'mdi-account-convert', key: 'menu.blank', text: 'Data Clerk Activation', link: '/dCRequestActivation' },
    { icon: 'mdi-chart-bar', key: 'menu.blank', text: 'Farmers Stats(LGA)', link: '/farmersStatsLGA' },
    { icon: 'mdi-chart-bar-stacked', key: 'menu.blank', text: 'Farmers Stats(Crop)', link: '/farmersStatsCommodity' },
    //{ icon: 'mdi-account-group', key: 'menu.blank', text: 'Commodity Association', link: '/summary' },
    //{ icon: 'mdi-chart-bell-curve', key: 'menu.blank', text: 'Summaries', link: '/summary' },
    //{ icon: 'mdi-bell', key: 'menu.blank', text: 'Picture', link: '/picture' } ,
    { icon: 'mdi-logout', key: 'menu.blank', text: 'Logout', link: '/' } 
  ]
}]
